/* set margin to prevent the overlap between content and arrows */
.slick-list {
  margin: 0 25px;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;

  > div {
    height: 100%;
  }
}
