@import "../../styles/variables.scss";

.container {
  background-color: #75a7ad;
}

.innerContainer {
  color: white;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  gap: 40px;
  padding: 40px 24px 90px;

  @include not-mobile {
    flex-direction: row;
    gap: 16px;
  }
}

.newsletterContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;

  a {
    text-decoration: underline;
  }
}

.newsletterThankYou {
  position: absolute;
  inset: 0;
  left: -5px;
  z-index: 1;
  background-color: #75a7ad;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletterHeading {
  color: var(--White, #fff);
  font-family: var(--Font-Family-Primary, Poppins);
  font-size: var(--font-size-heading-5-s, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-line-height-heading-5-s, 21px); /* 131.25% */
}

.checkboxContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: var(--White, #fff);
  font-family: var(--Font-Family-Primary, Poppins);
  font-size: var(--Font-Size-Small, 12px);
  font-style: normal;
  font-weight: 500;
  line-height: var(--Font-Line-height-Small, 18px); /* 150% */

  input {
    transform: scale(1.5);
    margin-top: 5px;
    cursor: pointer;
    border: none;
  }

  @media (max-width: $tablet-max) {
    input {
      margin-left: 5px;
    }
  }
}

.emailInput {
  display: flex;
  height: 48px;
  align-items: center;
  gap: var(--Font-Paragraph-spacing-Body, 8px);
  align-self: stretch;
  padding-left: 16px;
  border-radius: var(--Radius-Small, 6px);
  border: var(--Radius-None, 1px) solid var(--shade-300-t-disabled, #ccc);
  background: var(--White, #fff);
  width: 100%;
  color: #002238;

  &::placeholder {
    color: #b2b2b2;
  }
}

.inputHintText {
  font-size: 12px;
}

.privacyText {
  font-size: 11px;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

.belowInfoText {
  font-size: 11px;
  font-weight: 300;
}

// RIGHT PART
.rightContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include not-mobile {
    flex-direction: row;
    margin-left: auto;
    gap: 40px;
  }
}

.rightContainerSingleContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
  user-select: none;
  @include mobile {
    border: 1px solid white;
    border-radius: 10px;
    padding: 16px;
  }
}
.collapsed {
  .rightContainerSingleContentListItem {
    @include mobile {
      display: none;
    }
  }
}

.rightContainerSingleContentHeaderText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  @include mobile {
    &:hover {
      cursor: pointer;
    }
  }
}

.expansionIcon {
  display: none;
  @include mobile {
    display: block;
  }
}

.rightContainerSingleContentList {
  list-style-type: none;
  padding: 0;
}

.rightContainerSingleContentListItem {
  font-size: 16px;
  font-weight: 400;

  & a {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}
