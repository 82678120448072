@import "../../styles/variables.scss";

.header {
  display: none;

  @include not-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 100;
  }
}
