input#variant,
input#quantity {
  // on IE 11, an input with readonly attribute still has a cursor visible
  // setting width here on the inputs so that it's not visible B2B-551
  width: 0px;
}

// REACT DATE PICKER START

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.react-datepicker-wrapper {
  display: block;
}

/* Style for the header part of the datepicker */
.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}

/* Style for the month and year dropdown selectors */
.react-datepicker__month-select,
.react-datepicker__year-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  margin-top: 8px;
  margin-bottom: 8px;
}

/* Style for the month and year in the header */
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1em;
}

/* Style for the day names */
.react-datepicker__day-name {
  color: #000;
}

/* Style for each day in the datepicker */
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  border-radius: 50%;
  margin: 4px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}

/* Style for the selected day */
.react-datepicker__day--selected {
  background-color: #000;
  color: white;
}

/* Style for the day when hovered */
.react-datepicker__day:hover {
  background-color: #ccc;
}

/* Style for the navigation buttons (previous and next month) */
.react-datepicker__navigation {
  top: 20px;
}

/* Style for the navigation icons (arrows) */
.react-datepicker__navigation-icon {
  border-color: #000;
}

/* If you have navigation buttons for months and years separately,
you might want to style them individually */
.react-datepicker__navigation--previous {
  border-right-color: #000;
}

.react-datepicker__navigation--next {
  border-left-color: #000;
}

/* Further styles if you have time selection enabled */
.react-datepicker__time-container {
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time,
.react-datepicker__time-box {
  background-color: white;
}

.react-datepicker__time-list-item {
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  margin: 4px;
  text-align: center;
}

/* Style for the time when selected */
.react-datepicker__time-list-item--selected {
  background-color: #000;
  color: white;
}

.react-datepicker__current-month {
  display: none;
}

.react-datepicker__navigation {
  display: none;
  height: 0px;
  width: 0px;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  background-color: white;
  width: 100%;
}

.react-datepicker__month-dropdown-container {
  flex: 2;
}
.react-datepicker__month-select {
  cursor: pointer;
}

.react-datepicker__year-dropdown-container {
  flex: 1;
}

.react-datepicker__year-select {
  cursor: pointer;
}

.react-datepicker__header__dropdown {
  display: flex;
}

// REACT DATE PICKER END
