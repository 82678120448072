@import "../../../styles/variables.scss";

.container {
  height: $header-promo-height-mobile;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.promotionalBarText {
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px; /* 240% */
}
