@import "../../styles/variables.scss";

.header {
  display: none;

  @include mobile {
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 100;
  }
}
