.loadingWrapper {
  position: relative;
}

.loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.hiddenChildrenWrapper {
  opacity: 0;
  user-select: none;
}

.childrenWrapper {
  display: contents;
}
