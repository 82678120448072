@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @media (min-width: 1200px) {
    .container {
      box-sizing: content-box;
    }
  }
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  tab-size: 4;
  scroll-behavior: smooth;
}

body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
  color: #002238;
}

.wrapper {
  width: 100%;
}

@layer base {
  h1 {
    font-size: 2em;
    font-weight: 600;
  }
  h2 {
    font-size: 1.5em;
    font-weight: 600;
  }
  h3 {
    font-size: 1.17em;
    font-weight: 600;
  }
  h4 {
    font-size: 1em;
    font-weight: 600;
  }
  h5 {
    font-size: 0.83em;
    font-weight: 600;
  }
  h6 {
    font-size: 0.67em;
    font-weight: 600;
  }
}

@layer base {
  li {
    list-style: initial;
  }
}

.black {
  color: black;
}

.white {
  color: white;
}

.blue {
  color: #75a7ad;
}

.responsiveContainer {
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .responsiveContainer {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .responsiveContainer {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .responsiveContainer {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .responsiveContainer {
    max-width: 1392px;
    box-sizing: content-box;
  }
}

/* Product bundle slider */
.product-bundle-slider {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 30px;
}

.product-bundle-slider .slick-list {
  overflow: visible;
  width: 100%;
  margin: 0 -5px;
}

.product-bundle-slider .slick-track {
  display: flex;
  width: 100% !important;
  align-items: stretch;
}

.product-bundle-slider .slick-slide {
  width: 100% !important;
  flex: 0 0 100%;
  padding: 0 5px;
  height: auto !important;
}

.product-bundle-slider .slick-slide > div {
  width: 100%;
  height: 100%;
}

.product-bundle-slider .slick-slide > div > div {
  height: 100%;
}

.product-bundle-slider .slick-slide > div > div > div {
  height: 100%;
}

.product-bundle-slider .slick-dots {
  bottom: 0;
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  display: flex !important;
  justify-content: center;
  gap: 12px;
}

.product-bundle-slider .slick-dots li {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.product-bundle-slider .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 12px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #D9D9D9;
  border-radius: 50%;
  transition: all 0.2s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &:before {
    display: none;
  }
}

.product-bundle-slider .slick-dots li.slick-active button {
  position: relative;
  width: 16px;
  height: 16px;
  background: transparent;
  border: 2px solid #002238;
  border-radius: 50%;
  
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: #002238;
    border-radius: 50%;
  }
}

/* Disable link */
a.disabled {
  pointer-events: none;
}