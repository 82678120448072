@import "../../styles/variables";

@keyframes dotAnimation {
  0%,
  80%,
  100% {
    transform: scale(0) translateZ(0);
  }
  40% {
    transform: scale(1) translateZ(0);
  }
}

.spinnerContainer {
  position: relative;
  width: 100%;
}

.spinner {
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 50px;
  transform: translate(-50%, -50%);
}

.dot {
  width: 10px;
  height: 10px;
  background: $primary-deep-blue;
  border-radius: 50%;
  display: inline-block;
  animation: dotAnimation 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

.dot:nth-child(3) {
  animation-delay: 0s;
}
