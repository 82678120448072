@import "../../../styles/variables.scss";

.container {
  position: relative;
  margin: 0 auto;
  z-index: 1000;
}

.content {
  background-color: white;
  border-radius: 100px;
  box-shadow: 0px 3px 20px #5253531a;
  display: flex;
  height: 70px;
}

.leftContainer {
  align-items: center;
  display: flex;
  width: 220px;
  justify-content: center;
  padding: 20px;
}

.middleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  width: 100%;
}

.middleLeftContainer {
  display: flex;
  height: 100%;
}

.middleLeftItem {
  align-items: center;
  border-left: 1px solid #eceeef;
  display: flex;
  font-size: 13.5px;
  font-weight: 700;
  gap: 5px;
  padding: 0 12px;
}

.middleRightDesktop {
  display: none;
  @media only screen and (min-width: 1300px) {
    display: flex;
    gap: 20px;
    padding-right: 20px;
  }
}

.middleRightItem {
  align-items: center;
  display: flex;
  font-size: 13.5px;
  font-weight: 700;
}

.middleRightItemText {
  color: #002238;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
}

.leftHoverableItem {
  &:hover {
    background-color: #005d6e;
    color: white;
  }

  &:hover .dropdownMenu {
    opacity: 1;
    max-height: 1000px;
    transform: scaleY(1);
  }
}

.middleLeftItemText {
  color: #75a7ad;
  display: block;
  text-decoration: none;
}

.rightContainer {
  align-items: center;
  background-color: #75a7ad;
  display: flex;
  max-width: 152px;
  min-width: 152px;
  justify-content: space-around;
  padding: 0 20px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin-right: -1px;
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + 2px);
  background-color: white;
  left: 0;
  right: 0;
  padding: 20px 15px;
  border-radius: 50px;
  display: flex;
  gap: 20px;
  justify-content: center;
  transition:
    opacity 0.5s,
    max-height 0.2s,
    transform 0.2s;
  opacity: 0;
  max-height: 0;
  transform: scaleY(0);
  overflow: hidden;
}

.dropdownImageContainer {
  cursor: pointer;
  position: relative;
  border-radius: 20px;
}

.dropdownImageText {
  font-family: "Poppins";
  position: absolute;
  text-align: center;
  width: 100%;
  margin-top: 16px;
}

.middleRightTablet {
  display: none;
  position: relative;
  height: 100%;

  @media only screen and (max-width: 1299px) {
    display: flex;
    padding-right: 12px;
  }

  &:hover {
    cursor: pointer;
    .quickLinksMenu {
      opacity: 1;
      max-height: 1000px;
      transform: scaleY(1);
    }
  }
}

.quickLinksMenu {
  position: absolute;
  top: calc(100%);
  left: 0;
  background-color: white;
  box-shadow: -2px 6px 23px -8px rgba(0, 0, 0, 0.53);
  border-radius: 10px;
  width: 200px;
  transition:
    opacity 0.5s,
    max-height 0.2s,
    transform 0.2s;
  opacity: 0;
  max-height: 0;
  transform: scaleY(0);
  overflow: hidden;
}

.quickLinkContainer {
  padding: 10px 10px;
}

.quickLinkText {
  padding: 10px;
  font-weight: 400;
  border-bottom: 1px solid #ebedee;

  &:hover {
    text-decoration: underline;
  }
}

.cartCount {
  position: absolute;
  top: 1px;
  left: 20px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e56c5c;
  color: white;
  border-radius: 100%;
  font-family: Poppins;
  padding: 5px;
  font-size: 10px;
  font-weight: 700;
}

.cartLogo {
  position: relative;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileLogo {
  position: relative;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
