@import "colors";
@import "variables";

/* Contents of nprogress/nprogress.css with less styles :) */
#nprogress {
  pointer-events: none;

  .bar {
    background: #75a7ad;

    position: fixed;
    // Header is 100 currently, and we want to be on top of that (pun intended)
    z-index: 100000;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }

  .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow:
      0 0 10px #75a7ad,
      0 0 5px #75a7ad;
    opacity: 1;
    transform: rotate(3deg) translate(0px, -4px);
  }
}
