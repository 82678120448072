@import "../../../styles/variables.scss";

.container {
  height: $header-height-mobile;
  background-color: white;
  display: flex;
  box-shadow: 0px 3px 20px #5253531a;
}

.topLeft {
  flex-basis: 15%;
  background-color: #75a7ad;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topMiddle {
  flex-basis: 55%;
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;
}

.topRight {
  flex-basis: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  margin-right: 16px;

  @media (max-width: 375px) {
    gap: 12px;
    margin-right: 8px;
  }
}

.subContainer {
  height: $sub-header-height-mobile;
  background-color: #f6f5f6;
  display: flex;
}

.subContainerItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-right: 1px solid #eceeef;
}

.subContainerItemText {
  text-decoration: none;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
}

.sideMenuOverlayContainer {
  background-color: rgba(0, 0, 0, 0.5);
  height: calc(100vh - 195px); // allow scrolling on very short phone screens
  overflow-y: auto;
  position: fixed;
  top: 195px;
  left: 0;
  right: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
}

.sideMenuContentContainer {
  background-color: white;
  flex-grow: 1;
  width: 90%;
}

.sideMenuTopContainer {
  display: flex;
  width: 100%;
  margin: 20px 0;
  flex-wrap: wrap;
  align-items: flex-start;
}

.sideMenuTopSingleItem {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 0 15px;
  border-radius: 20px;
  overflow: hidden;
}

.sideMenuSingleTopImageText {
  font-family: Poppins;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
}

.sideMenuTextItemsContainer {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}

.sideMenuSingleItem {
  padding: 15px 0;
  border-top: 1px solid #eceeef;
}

.sideMenuSingleItemText {
  font-family: Poppins;
  font-size: 14px;
  color: #002238;
  font-weight: 400;
  text-decoration: none;
}

.cartLogo {
  position: relative;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cartCount {
  position: absolute;
  right: 20px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e56c5c;
  color: white;
  border-radius: 100%;
  font-family: Poppins;
  padding: 5px;
  font-size: 10px;
  font-weight: 700;
  z-index: 10;
}
